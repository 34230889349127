const $ = require('jquery');
import validate from 'jquery-validation';

$(function(){

'use strict';

/*******************************************
 * 
 * contact
 * https://jqueryvalidation.org/validate/
 * 
 *******************************************/

(function() {
    
    // element
    var elm_form = $('form[data-target=form]');
    var elm_submit = elm_form.find('[data-target=submit]');
    var elm_result = elm_form.find('[data-target=submit_result]');

    // var
    
    // init
    function init() {
        if(elm_form.length){
            setup();
            test_setup();
        }
    }

    //func
    function setup() {
        elm_form.validate({
            rules : {
                form_type : { required: true },
                form_name : { required: true },
                form_company : { required: true },
                form_mail : { required: true, email: true },
                form_tel : { required: true },
                form_zip : { required: true },
                form_address1 : { required: true },
                form_media : {  },
                form_comment : {  },
            },
            ignore: ".is-disabled",
            // groups: {
            // 	xxx: "xxx xxx",
            // },
            errorElement : "div",
            errorClass: "_error",
            messages: {
                form_type : {
                    required: "この項目は必須です。"
                },
                form_name : {
                    required: "この項目は必須です。"
                },
                form_company : {
                    required: "この項目は必須です。"
                },
                form_mail : {
                    required: "この項目は必須です。",
                    email: "メールアドレスが正しくありません。",
                },
                form_tel : {
                    required: "この項目は必須です。"
                },
                form_zip : {
                    required: "この項目は必須です。"
                },
                form_address1 : {
                    required: "この項目は必須です。"
                },
            },
            errorPlacement: function(error, element){
                // if( element.is(":radio") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.is(":checkbox") ){
                //     error.appendTo( element.parent().parent().parent().parent() );
                // }
                // if( element.attr("name")=="xxx" ){
                //     error.appendTo( element.parent() );
                // }
                // This is the default behavior
                error.appendTo( element.parents('.FormRows__item').find('.FormRows__error') );
            }
        });

        elm_submit.on('click',function(){
            if (!elm_form.valid()) {
                return false;
            }
            $(this).addClass('is-disabled');

            var formdata = elm_form.serialize();
            elm_result.css('visibility','visible');

            $.ajax(
                {
                    type: 'post',
                    url: './form_contact/app.php',
                    data: formdata+'&act_submit=1',
                    dataType: 'JSON'
                }
            ).then(
                function(data, status, xhr){
                    elm_result.html(data['message']);
                },
                function(xhr, status, error){
                    elm_result.html(data['message']);
                    console.log(xhr, "\n" + status, "\n" + error);
                }
            );
            return false;
        });
    }

    function test_setup(){
        $("[data-target=form_test]").on('click', function () {
            $('[name=form_type]').val('詳しく話を聞きたい');
            $('[name=form_name]').val('名前名前名前');
            $('[name=form_company]').val('会社名会社名');
            $('[name=form_mail]').val('dummy@test.a.a');
            $('[name=form_zip]').val('123-4567');
            $('[name=form_address1]').val('住所住所住所住所住所1');
            $('[name=form_address2]').val('住所住所住所住所住所2');
            $('[name=form_tel]').val('0123456789');
            $('[name=form_media]').eq(2).prop('checked', true);
            $('[name=form_comment]').val('コメントコメントコメント');
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});