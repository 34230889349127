const $ = require('jquery');

$(function(){

'use strict';

/*******************************************
 * 
 *  deviceObserver
 * 
********************************************/

(function() {

    // constructor
    var DeviceObserver = function() {
        this.$window = $( window );
        this.$document = $( 'html, body' );

        this.isMobileSE = null;
        this.isMobile = null;
        this.isTablet = null;
        this.isDesktop = null;
        this.isDesktopWide = null;
        
        this.PreviousMobileSE = null;
        this.PreviousMobile = null;
        this.PreviousTablet = null;
        this.PreviousDesktop = null;
        this.PreviousDesktopWide = null;

        this.MobileSEQuery = 'screen and (max-width: 374px)';
        this.MobileQuery = 'screen and (min-width: 375px) and (max-width: 749px)';
        this.TabletQuery = 'screen and (min-width: 750px) and (max-width: 959px)';
        this.DesktopQuery = 'screen and (min-width: 960px) and (max-width: 1279px)';
        this.DesktopWideQuery = 'screen and (min-width: 1280px)';

        this.events = {
            Change: 'Change',
            MobileSE: 'MobileSE',
            Mobile: 'Mobile',
            Tablet: 'Tablet',
            Desktop: 'Desktop',
            DesktopWide: 'DesktopWide',
        };

        this.setup();
        //bind event
        window.addEventListener('resize', this.onResize.bind(this));
    };

    // member
    DeviceObserver.prototype.setup = function() {
        
        this.isMobileSE = false;
        this.isMobile = false;
        this.isTablet = false;
        this.isDesktop = false;
        this.isDesktopWide = false;

        this.PreviousMobileSE = false;
        this.PreviousMobile = false;
        this.PreviousTablet = false;
        this.PreviousDesktop = false;
        this.PreviousDesktopWide = false;

        if(window.matchMedia( this.MobileSEQuery ).matches){
            this.isMobileSE = true;
        }else if(window.matchMedia( this.MobileQuery ).matches){
            this.isMobile = true;
        }else if(window.matchMedia( this.TabletQuery ).matches){
            this.isTablet = true;
        }else if(window.matchMedia( this.DesktopQuery ).matches){
            this.isDesktop = true;
        }else if(window.matchMedia( this.DesktopWideQuery ).matches){
            this.isDesktopWide = true;
        }
    };

    DeviceObserver.prototype.onResize = function() {

        //前回の値の保存
        this.PreviousMobileSE = this.isMobileSE;
        this.PreviousMobile = this.isMobile;
        this.PreviousTablet = this.isTablet;
        this.PreviousDesktop = this.isDesktop;
        this.PreviousDesktopWide = this.isDesktopWide;

        if(window.matchMedia( this.MobileSEQuery ).matches){
            if(!this.isMobileSE){
                //this.isMobileSE = false;
                this.isMobile = false;
                this.isTablet = false;
                this.isDesktop = false;
                this.isDesktopWide = false;

                this.isMobileSE = true;
                this.$window.trigger(this.events.Change);
                this.$window.trigger(this.events.MobileSE);
            }
        }else if(window.matchMedia( this.MobileQuery ).matches){
            if(!this.isMobile){
                this.isMobileSE = false;
                //this.isMobile = false;
                this.isTablet = false;
                this.isDesktop = false;
                this.isDesktopWide = false;

                this.isMobile = true;
                this.$window.trigger( this.events.Change );
                this.$window.trigger( this.events.Mobile );
            }
        }else if(window.matchMedia( this.TabletQuery ).matches){
            if(!this.isTablet){
                this.isMobileSE = false;
                this.isMobile = false;
                //this.isTablet = false;
                this.isDesktop = false;
                this.isDesktopWide = false;
                
                this.isTablet = true;
                this.$window.trigger(this.events.Change);
                this.$window.trigger(this.events.Tablet);
            }
        }else if(window.matchMedia( this.DesktopQuery ).matches){
            if(!this.isDesktop){
                this.isMobileSE = false;
                this.isMobile = false;
                this.isTablet = false;
                //this.isDesktop = false;
                this.isDesktopWide = false;

                this.isDesktop = true;
                this.$window.trigger(this.events.Change);
                this.$window.trigger(this.events.Desktop);
            }
        }else if(window.matchMedia( this.DesktopWideQuery ).matches){
            if(!this.isDesktopWide){
                this.isMobileSE = false;
                this.isMobile = false;
                this.isTablet = false;
                this.isDesktop = false;
                //this.isDesktopWide = false;

                this.isDesktopWide = true;
                this.$window.trigger(this.events.Change);
                this.$window.trigger(this.events.DesktopWide);
            }
        }
    };

    // init
    window.deviceObserver = new DeviceObserver();

})();

/*******************************************
 * 
 *  add _blank to external link 
 * 
********************************************/

(function() {

    // Elements
    var elm = $("a[href^='http://'],a[href^='https://']");

    // Vars
    var exarr = [
        "localhost",
        "192.168.",
        "127.0.0.1"
    ];

    // init
    function init() {
        exarr.push(location.hostname);
        elm.each(function(){
            if(typeof $(this).attr('target') != 'undefined') return;
            for(var i=0;i<exarr.length;++i){
                if(!$(this).attr('href').indexOf("http://"+exarr[i])) return;
                if(!$(this).attr('href').indexOf("https://"+exarr[i])) return;
            }
            $(this).attr('target','_blank');
        });
    }

    init();

})();

/*******************************************
 * 
 *  navibutton
 * 
********************************************/

(function() {

    // Elements
    var elm_button = $('.global-NaviButton');

    // Vars

    // init
    function init() {
        elm_button.on('click', function(){
            $('body').toggleClass('is-naviOpened');
        });
    }

    init();

})();


/*******************************************
 * 
 *  scroll link
 * 
********************************************/

(function() {

    // Vars
    var speed = 400;

    // init
    function init() {
        $("a[data-target=scroll]").each(function(){
            $(this).on('click', function(){
                var href = $(this).attr("href").replace(/^https?:\/\/[^/]+/,'');
                var index = href.indexOf("#");

                var currentPath = location.pathname.replace(/\/$/,'');
                var hrefPath = "";
                if(index < 0){
                    hrefPath = href.replace(/\/$/,'');
                }else{
                    hrefPath = href.slice(0,index).replace(/\/$/,'');
                }

                if(currentPath === hrefPath){
                    if(index < 0){
                        $('body,html').animate({scrollTop:0}, speed, 'swing');
                        $('body').removeClass('is-naviOpened');
                        return false;
                    }else{
                        var target = $(href.slice(index));
                        if(target.length){
                            var position = target.offset().top;
                            $('body,html').animate({scrollTop:position}, speed, 'swing');
                            $('body').removeClass('is-naviOpened');
                            return false;
                        }
                    }
                }
            });
        });
    }

    init();

})();


/*******************************************
 * 
 *  scrolling
 * 
********************************************/

(function() {

    // Elements

    // Vars

    // init
    function init() {
        scrolling();
        $(window).on('scroll', scrolling);
    }

    function scrolling(){
        var t = window.pageYOffset;
        var wh = document.documentElement.clientHeight;
        var dh = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        
        if( t < 20 ){
            //top
            $('body').addClass('is-top');
            $('body').removeClass('is-middle');
            $('body').removeClass('is-bottom');
        }else if( t > (dh-wh-20) ){
            //bottom
            $('body').addClass('is-bottom');
            $('body').removeClass('is-top');
            $('body').removeClass('is-middle');
        }else{
            //middle
            $('body').addClass('is-middle');
            $('body').removeClass('is-top');
            $('body').removeClass('is-bottom');
        }

        var elm_navi = $('.global-NaviBlock');
        var elm_navi_float = $('.global-NaviBlock__float');
        var navi_h = elm_navi.offset().top;
        if( navi_h < t ){
            elm_navi.addClass('is-active');
            elm_navi_float.addClass('is-active');
        }else{
            elm_navi.removeClass('is-active');
            elm_navi_float.removeClass('is-active');
        }
    }

    init();

})();

/*******************************************
********************************************/
});