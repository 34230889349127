const $ = require('jquery');

$(function(){

'use strict';

/*******************************************
 * 
 * qa
 * 
 *******************************************/

(function() {

    // init
    function init() {
        setup();
    }

    //func
    function setup() {
        $('body').on('click', '.qa-QaItems__item', function(e){
            var elm = $(e.currentTarget);
            if(elm.hasClass('is-active')){
                elm.removeClass('is-active');
                elm.find('.qa-QaItems__a').css('height',0);
            }else{
                elm.addClass('is-active');
                var h = elm.find('.qa-QaItems__a-inner').innerHeight();
                elm.find('.qa-QaItems__a').css('height',h);
            }
        });
    }

    init();

})();

/*******************************************
 *******************************************/
});