const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * slider
 * 
 *******************************************/

(function() {

    // element
    var elm_slider = $('.production-Slider');

    // var
    
    // init
    function init() {
        if(elm_slider.length){
            setup();
        }
    }

    //func
    function setup() {
        elm_slider.on('init', function(event, slick, currentSlide, nextSlide){
        });
        elm_slider.slick({
            dots: false,
            arrows: true,
            autoplay : false,
            speed: 600,
            pauseOnHover: false,
            pauseOnFocus: false,
            touchMove: true,
            centerMode: true,
            variableWidth: true,
            touchThreshold: 20,
        });
        elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        });
    }

    init();

})();

/*******************************************
 * 
 * photoswipe
 * 
 *******************************************/

(function() {
    
    // init
    function init() {
        setup();
    }

    //func
    function setup() {
        $('body').on('click', '[data-target=pswp]', function(){
            openPhotoSwipe($(this).attr('data-index'));
        });
    }

    function openPhotoSwipe(startIndex){
        var pswpElement = document.querySelectorAll('.pswp')[0];
        
        var items = [];
        $('[data-target=pswpSrc]').each(function(){
            var dataIndex = $(this).attr('data-index');
            var item = {};
            item.src = $(this).attr('data-src');
            item.w = 0;
            item.h = 0;
            item.title = $(this).attr('data-title');
            items[Number(dataIndex)] = item;
        });
        
        // define options (if needed)
        var options = {
            // optionName: 'option value'
            // for example:
            index: Number(startIndex) // start at first slide
        };

        // Initializes and opens PhotoSwipe
        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

        //getting size
        gallery.listen('gettingData', function(index, item) {
            if (item.w < 1 || item.h < 1) {
                var img = new Image(); 
                img.onload = function() {
                    item.w = this.width;
                    item.h = this.height;
                    gallery.invalidateCurrItems();
                    gallery.updateSize(true);
                }
                img.src = item.src;
            }
        });
        gallery.init();
    }

    init();

})();

/*******************************************
 *******************************************/
});